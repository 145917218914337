import { createSelector } from 'reselect';

import Colors from '../styles/colors';

const getAppState = (state) => state.app;

export const getJurisdictions = createSelector(
    getAppState,
    (appState) => appState.jurisdictions,
);

export const getLanguages = createSelector(
    getAppState,
    (appState) => appState.languages,
);

export const getMobileNavigationOpenState = createSelector(
    getAppState,
    (appState) => appState.mobileNavOpen,
);

export const getConfigLoaded = createSelector(
    getAppState,
    (appState) => appState.configLoaded,
);
export const getAPIStatus = createSelector(
    getAppState,
    (appState) => appState.apiStatus,
);

export const getAppConfig = createSelector(
    getAppState,
    (appState) => appState.config,
);

export const getAuthorizedFeatures = createSelector(
    getAppState,
    (appState) => appState.config.features,
);

export const getPrivacyPolicy = createSelector(
    getAppState,
    (appState) => appState.privacy,
);

export const getTerms = createSelector(
    getAppState,
    (appState) => appState.terms,
);

export const getApplicantTerms = createSelector(
    getAppState,
    (appState) => appState.applicantTerms,
);

export const getAppImages = createSelector(
    getAppState,
    (appState) => appState.config.images,
);

export const getAppConfigTheme = createSelector(
    getAppState,
    (appState) => ({
        typography: {
            ...appState.config.typography,
        },
        palette: appState.config.colors,
        userRoles: {
            ...appState.config.userRoles,
        },
        // if we don't override the components in this theme
        // then we can't get the colors to properly propogate
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        padding: '3px !important',
                        '&:hover, :focus': {
                            backgroundColor: appState.config.colors.primary.hover,
                            borderRadius: 6,
                            transition: 'none',
                            '& .MuiSvgIcon-root': {
                                fill: appState.config.colors.primary.main,
                            },
                        },
                        '&:focus': {
                            boxShadow: `inset 0 0 2px 1px ${appState.config.colors.primary.main}`,
                        },
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        '& button.MuiIconButton-root svg.MuiSvgIcon-root': {
                            color: appState.config.colors.primary.main,
                        },
                        '& button.MuiIconButton-root.Mui-disabled svg.MuiSvgIcon-root': {
                            color: Colors.darkGray,
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        display: 'flex',
                        justifyContent: 'center',
                        height: 3,
                        backgroundColor: 'transparent',
                        '& > span': {
                            display: 'flex',
                            height: 3,
                            backgroundColor: appState.config.colors.primary.main,
                            width: 'calc(100% - 30px)',
                        },
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        marginRight: 0,
                        '&:hover': {
                            backgroundColor: appState.config.colors.primary.hover,
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        '&.MuiTab-root': {
                            color: appState.config.typography?.color,
                            paddingRight: 15,
                            paddingLeft: 15,
                            '&.Mui-selected': {
                                color: appState.config.typography?.h1?.color,
                            },
                        },
                    },
                },
            },

            MuiFormControlLabel: { // checkboxes, radio buttons
                styleOverrides: {
                    root: {
                        '&.Radio-label': {
                            '&:hover': {
                                border: `1px solid ${appState.config.colors.primary.main}`,
                            },
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        '&$focused': {
                            border: `1px solid ${appState.config.colors.primary.main}`,
                        },
                        '&:hover': {
                            border: `1px solid ${appState.config.colors.primary.main}`,
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${appState.config.colors.primary.main}`,
                            },
                        },
                        '&:hover': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${appState.config.colors.primary.main} !important`,
                            },
                        },
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: appState.config.colors.primary.main,
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        color: appState.config.typography?.h1?.color,
                    },
                },
            },
        },
    }),
);

export const getContactEmail = createSelector(
    getAppState,
    (appState) => appState.config.contactInfo.email,
);

export const getCreateMatterFormQuestions = createSelector(
    getAppState,
    (appState) => appState.config.createMatterFormQuestions,
);
