import { createTheme } from '@mui/material/styles';

import Colors from './colors';

const theme = createTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistancy
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    spacing: 5,
    padding: 5,
    // mui attributes
    typography: {
        useNextVariants: true,
        // allVariants: {},
        h1: {
            fontSize: '2.4rem',
            fontWeight: 700,
            fontFamily: 'inherit',
        },
        h2: {
            fontSize: '1.6rem',
            fontWeight: 700,
            fontFamily: 'inherit',
        },
        h3: {
            fontSize: '1.4rem',
            fontWeight: 400,
            letterSpacing: 0.34,
            lineHeight: '2rem',
            fontFamily: 'inherit',
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 700,
            fontFamily: 'inherit',
        },
        h5: {
            fontSize: '1.3rem',
            fontWeight: 600,
            fontFamily: 'inherit',
        },
        body1: {
            fontSize: '1.2rem',
            fontWeight: 400,
            color: Colors.charcoal,
            letterSpacing: 0.4,
            fontFamily: 'inherit',
        },
        body2: {
            fontSize: '1.4rem',
            fontWeight: 400,
            color: Colors.darkBlack,
            fontFamily: 'inherit',
        },
        button: {
            fontSize: '1.3rem',
            fontWeight: '600',
            textTransform: 'none',
            fontFamily: 'inherit',
        },
    },
    palette: {
        text: {
            primary: Colors.darkBlack,
        },
        primary: {
            main: '#000',
            dark: Colors.darkBlack,
            contrastText: Colors.white, // text color on buttons
        },
        background: {
            default: Colors.lightBeige,
        },
        error: {
            main: Colors.error,
        },
        success: {
            main: Colors.success,
        },
    },
    components: {
        MuiList: {
            styleOverrides: {
                root: {
                    '&.filterMenu .MuiMenuItem-root': {
                        fontSize: '1.3rem',
                        paddingTop: '6px',
                        paddingBottom: '6px',
                    },
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: Colors.darkBlack,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    top: 'calc(50% - 6px)',
                },
                root: {
                    '&:before, :hover:not(.Mui-disabled):before': {
                        borderBottom: 0,
                    },
                    '& .Mui-disabled': {
                        cursor: 'not-allowed',
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    marginBottom: '-9px',
                },
                colorPrimary: {
                    color: 'inherit',
                    backgroundColor: 'transparent',
                    border: 0,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: 3,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiTab-root': {
                        fontSize: '1.4rem',
                        fontWeight: 700,
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                textColorInherit: {
                    opacity: 0.5,
                    color: Colors.textGrey,
                    '&$selected': {
                        color: Colors.textGrey,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            styleOverrides: {
                label: {
                    color: `rgba(${Colors.textGreyRGB}, 0.7)`,
                    fontSize: '1.4rem',
                    fontWeight: 600,
                },
                root: {
                    marginLeft: 0, // not sure why this is set to default at -11px, noting incase this adjustment causes oddities.
                    '&.Checkbox-label': {
                        '& $label': {
                            fontWeight: 400,
                        },
                        color: Colors.textGrey,
                    },

                    '&.Checkbox-label-checked': {
                        color: Colors.darkBlack,
                        '& .MuiFormControlLabel-label': {
                            // color: Colors.darkBlack,
                        },
                    },

                    '&.Radio-label': {
                        paddingRight: 15,
                        border: '1px solid rgba(171,168,158,0.2)',
                        borderRadius: 6,

                        '&:hover': {
                            borderRadius: 6,
                            backgroundColor: Colors.lightBeige,
                        },
                    },

                    '&.Radio-label-checked': {
                        backgroundColor: Colors.lightBeige,
                        '& $label': {
                            color: Colors.textGrey,
                        },
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                // if RadioGroup has nooutlines param set to true
                root: {
                    '&[data-nooutlines=true]': {
                        '& .Radio-label': {
                            border: 'none',
                            '&:hover': {
                                border: 'none',
                            },
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: Colors.darkBlack,
                    fontSize: '1.3rem',
                    fontWeight: 600,
                    letterSpacing: '0.025rem',
                    '&.Mui-disabled': {
                        color: Colors.darkBlack,
                    },
                    '&.Mui-focused, &.Mui-$error': {
                        color: Colors.darkBlack,
                    },
                },
                asterisk: {
                    color: Colors.error,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    maxWidth: '100%',
                    whiteSpace: 'normal',
                    color: Colors.darkBlack,
                    fontSize: '1.3rem',
                    fontWeight: 700,
                    textTransform: 'none',
                    '&.Mui-focused, & .Mui-error': {
                        color: `${Colors.darkBlack} !important`,
                    },
                },
                shrink: {
                    transform: 'translate(0, 1.5px) scale(1)',
                },
                asterisk: {
                    color: Colors.error,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                contained: {},
                root: {
                    marginLeft: 0,
                    fontSize: '1.3rem',
                    color: Colors.charcoal,
                    fontWeight: 600,
                    fontFamily: 'inherit',
                    letterSpacing: 'normal',
                    '&.MuiFormHelperText-error': {
                        color: Colors.charcoal,
                    },
                    '&[role="alert"]': {
                        color: Colors.error,
                        fontStyle: 'normal',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    color: Colors.textGrey,
                    '& ::placeholder': {
                        color: Colors.darkGray,
                        opacity: 1,
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.filledBg,
                    color: '#222222',
                    fontSize: '1.3rem',
                    width: '100%',
                    borderRadius: 3,
                    marginTop: 25,
                    border: '1px solid rgba(171,168,158,0.2)',
                    '&$focused': {},
                    '&:hover': {
                        backgroundColor: Colors.white,
                    },
                    '&$error, &$error$focused': {
                        borderColor: Colors.error,
                    },
                    '&$disabled': {
                        color: `rgb(${Colors.charcoalRGB}, 0.5)`,
                        borderColor: 'rgba(171,168,158,0.2)',
                        backgroundColor: '#f5f6f5',
                        '& svg': {
                            color: Colors.grey,
                        },
                    },
                },
                input: {
                    padding: 15,
                    '&::placeholder': {
                        color: `rbga(${Colors.darkBlackRGB}, 0.7)`,
                        fontWeight: 400,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.white,
                    color: '#222222',
                    fontSize: '1.3rem',
                    width: '100%',
                    borderRadius: 3,
                    marginTop: 25,
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid rgba(171,168,158,0.5)',
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {},
                    },
                    '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {},
                    },
                    '&.MuiOutlinedInput-error, &.MuiOutlinedInput-error.MuiOutlinedInput-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.error,
                        },
                    },
                    '&.MuiOutlinedInput-disabled': {
                        color: '#0F2032',
                        backgroundColor: '#f5f6f5',
                        '& svg': {
                            color: Colors.gray,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#f5f6f5',
                        },
                    },
                },
                input: {
                    padding: '16px 15px',
                    '&::placeholder': {
                        color: `rbga(${Colors.darkBlackRGB}, 0.7)`,
                        fontWeight: 400,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    transform: 'scale(1.4)',
                    color: `rgba(${Colors.charcoalRGB}, 0.5)`,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: Colors.lightBeige,
                    },

                    '&.Mui-checked': {},
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation22: { // select menus, popover navigation
                    borderRadius: 8,
                    boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
                },
                elevation1: { // table bgs
                    borderRadius: 19,
                },
                elevation2: { // regular menus
                    borderRadius: 5,
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
                },
                elevation23: { // data tables
                    boxShadow: 'none',
                    borderRadius: 19,
                },
                elevation24: { // dialogs
                    borderRadius: 5,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    backgroundColor: Colors.selectBg,
                    color: Colors.darkBlack,
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineHeight: '2.2rem',
                    letterSpacing: 0.5,

                    '&:hover': {
                        color: Colors.blue,
                        backgroundColor: Colors.selectHover,
                    },

                    '&[aria-selected=true]': {
                        color: Colors.darkBlack,
                        backgroundColor: Colors.selectHover,
                    },

                    '&[role=option]': { // select menu options
                        paddingTop: 12,
                        paddingBottom: 12,
                        paddingLeft: 22,
                        paddingRight: 22,
                    },

                    '&[role=menu]': { // menu items

                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    minWidth: 'fit-content',
                    lineHeight: '1.9rem',
                    textTransform: 'none',
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    borderRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 24,
                    paddingRight: 24,
                    letterSpacing: 0,

                    '&:active, &[data-clicked=true]': {},
                },
                root: {
                    '&.Mui-disabled, &[aria-disabled=true]': {
                        color: `rgba(${Colors.darkGrayRGB}, 0.3) !important`,
                        backgroundColor: `${Colors.disabledBtnBackground} !important`,
                        border: '0px !important',
                        opacity: 1,
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '24px 24px 18px',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                dividers: {
                    // only show top divider
                    borderBottom: 0,
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: 24,
                },
                spacing: {
                    '& > :not(:first-child)': {
                        marginLeft: 12,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    border: `1px solid ${Colors.gray}`,
                    boxShadow: `0 1px 10px 0 rgba(${Colors.darkBlackRGB}, 0.1)`,
                    marginBottom: 16,
                    display: 'flex',
                    justifyContent: 'space-between',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '20px 24px',
                    width: '100%',
                },
            },
        },
        // Date Picker
        MuiInputAdornment: {
            styleOverrides: {
                positionEnd: {
                    paddingRight: 20,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover, &:focus': {},
                },
            },
        },

        MuiSvgIcon: {
            styleOverrides: {
                root: {},
            },
        },

        MuiPickersToolbarText: {
            styleOverrides: {
                toolbarTxt: {
                    color: Colors.white,
                },
            },
        },

        MuiPickersCalendarHeader: {
            styleOverrides: {
                iconButton: {
                    '&:hover, &:focus': {},
                },
            },
        },

        MuiPickersDay: {
            styleOverrides: {
                day: {
                    '&:hover, &:focus': {},
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 30,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: Colors.darkBlack,
                    fontSize: '1.4rem',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                },
                action: {
                    padding: 0,
                    paddingLeft: 16,
                },
                filledSuccess: {
                    color: Colors.white,
                    backgroundColor: Colors.textGrey,
                    '& a': {
                        color: Colors.white,
                    },
                },
                filledError: {
                    color: Colors.white,
                    backgroundColor: Colors.error,
                    '& a': {
                        color: Colors.white,
                    },
                },
                filledInfo: {
                    color: Colors.white,
                    backgroundColor: Colors.textGrey,
                    '& a': {
                        color: Colors.white,
                    },
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    backgroundColor: 'none',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    borderRadius: '19px',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                footer: { // no data
                    fontSize: '1.2rem',
                    color: Colors.midGray,
                    fontWeight: 700,
                    textAlign: 'center',
                    paddingTop: 30,
                    paddingBottom: 30,
                },
                root: {
                    paddingTop: 4,
                    paddingBottom: 4,
                    fontSize: '1.3rem',
                },
                head: {
                    fontSize: '1.2rem',
                    color: Colors.darkGray,
                    letterSpacing: 0.4,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                    paddingRight: 0,
                },
                stickyHeader: {
                    height: 46,
                    backgroundColor: Colors.white,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: Colors.darkGray,
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 1200px)': {
                        maxWidth: '1140px',
                    },
                },
            },
        },
    },
});

export default theme;
