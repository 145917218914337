import cloneDeep from 'lodash/cloneDeep';

import MatterTypes from '../action-types/matters';
import { createMatterInitialState, importMattersInitialState, importLegalServerInitialState } from '../constants';

const internals = {
    initial: () => ({
        mattersJSON: null,
        activityLog: null,
        matterTypesCount: null,
        matterTypes: [],
        matterColors: [],
        mattersReportCount: null,
        paginatedMatterTypes: {
            params: {
                page: 0,
                pageSize: 10,
                sortBy: 'name',
                sortOrder: 'asc',
            },
            results: [],
            total: null,
        },
        paginatedMatterTypesStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        mattersCount: null,
        paginatedMatters: {
            params: {
                page: 0,
                pageSize: 10,
                sortBy: 'applicantName',
                sortOrder: 'asc',
                filter: { status: '' },
            },
            results: null,
            total: null,
        },
        paginatedMattersStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        createMatter: createMatterInitialState,
        createMatterStatus: {
            error: false,
            completed: false,
        },
        importLegalServerData: importLegalServerInitialState,
        importLegalServerDataStatus: {
            error: false,
            completed: false,
        },
        importMatters: importMattersInitialState,
        importMattersStatus: {
            error: false,
            completed: false,
        },
        viewMatter: null,
        viewMatterStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        markMatterReadyForReviewStatus: {
            error: false,
            completed: false,
        },
        markMatterClosedStatus: {
            error: false,
            completed: false,
        },
        markMatterOpenStatus: {
            error: false,
            completed: false,
        },
        deleteMatterStatus: {
            error: false,
            completed: false,
        },
        assignAttorneysStatus: {
            error: false,
            completed: false,
        },
        documentsUploadedStatus: {
            error: false,
            completed: false,
        },
        reportIssueStatus: {
            error: false,
            completed: false,
        },
        uploadedFileList: [], // used by uploader component
    }),
};

const MatterTypeTransform = (data) => {
    const matterTypes = cloneDeep(data);

    for (let i = 0; i < matterTypes.length; i += 1) {
        if (matterTypes[i].outcomeQuestions) {
            for (let q = 0; q < matterTypes[i].outcomeQuestions.length; q += 1) {
                if (matterTypes[i].outcomeQuestions[q].options) {
                    // as an admin editing the options, we need them in a single string format
                    // as a user filling the outcome question form, we need the options in the API provided array format
                    matterTypes[i].outcomeQuestions[q].dropDownOptions = matterTypes[i].outcomeQuestions[q].options;
                    matterTypes[i].outcomeQuestions[q].options = matterTypes[i].outcomeQuestions[q].options.join('\r\n');
                }
            }
        }
    }

    return matterTypes;
};

const MattersReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case MatterTypes.UPDATE_MATTER_STAGE_SUCCESS: {
        return {
            ...state,
            viewMatter: {
                ...state.viewMatter,
                ...payload,
            },
        };
    }

    case MatterTypes.MARK_MATTER_MESSAGES_READ: {
        return {
            ...state,
            viewMatter: {
                ...state.viewMatter,
                assignees: payload,
            },
        };
    }

    case MatterTypes.GET_MATTER_MESSAGES_ERROR: {
        return {
            ...state,
            viewMatter: {
                ...state.viewMatter,
                messages: [],
            },
        };
    }
    case MatterTypes.GET_MATTER_MESSAGES_SUCCESS: {
        return {
            ...state,
            viewMatter: {
                ...state.viewMatter,
                messages: payload,
            },
        };
    }
    case MatterTypes.SETUP_UPLOADER: {
        return {
            ...state,
            uploadedFileList: payload,
        };
    }
    case MatterTypes.RESET_UPLOAD: {
        return {
            ...state,
            uploadedFileList: [],
        };
    }
    case MatterTypes.UPLOAD_DELETE: {
        const remainingItems = [...state.uploadedFileList];
        remainingItems.splice(payload, 1);
        return {
            ...state,
            uploadedFileList: remainingItems,
        };
    }
    case MatterTypes.UPLOAD_BEGIN:
    case MatterTypes.UPLOAD_SUCCESS:
    case MatterTypes.UPLOAD_ERROR: {
        // test to see if we are passing an object with an listIndex
        if (state.uploadedFileList.length === 0) {
            return {
                ...state,
                uploadedFileList: [payload],
            };
        }

        const objIndex = state.uploadedFileList.findIndex(((obj) => obj.listIndex === payload.listIndex));
        if (objIndex === -1) {
            return {
                ...state,
                uploadedFileList: [...state.uploadedFileList, payload],
            };
        }
        const modifiedArray = [...state.uploadedFileList];
        modifiedArray[objIndex] = payload;
        return {
            ...state,
            uploadedFileList: modifiedArray,
        };
    }
    case MatterTypes.GET_MATTERS_REPORT_SUCCESS: {
        return {
            ...state,
            mattersReportCount: payload.matterCount,
        };
    }
    case MatterTypes.GET_MATTERS_JSON_BEGIN: {
        return {
            ...state,
            mattersJSON: null,
        };
    }
    case MatterTypes.GET_MATTERS_JSON_SUCCESS: {
        return {
            ...state,
            mattersJSON: payload,
        };
    }
    case MatterTypes.GET_ACTIVITY_SUCCESS: {
        return {
            ...state,
            activityLog: payload,
        };
    }
    case MatterTypes.GET_MATTERS_COUNT_SUCCESS: {
        return {
            ...state,
            mattersCount: { ...payload },
        };
    }
    case MatterTypes.UPDATE_PAGINATED_MATTERS_PARAMS: {
        const newState = { ...state };
        newState.paginatedMatters.params = {
            ...state.paginatedMatters.params,
            ...payload,
        };
        return newState;
    }
    case MatterTypes.GET_PAGINATED_MATTERS_BEGIN:
        return {
            ...state,
            paginatedMattersStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case MatterTypes.GET_PAGINATED_MATTERS_ERROR:
        return {
            ...state,
            paginatedMattersStatus: {
                error: true,
                errorMsg: '',
                completed: true,
            },
        };
    case MatterTypes.GET_PAGINATED_MATTERS_SUCCESS:
        return {
            ...state,
            paginatedMattersStatus: {
                error: false,
                errorMsg: '',
                completed: true,
            },
            paginatedMatters: {
                ...state.paginatedMatters,
                results: payload.results,
                total: payload.total,
            },
        };
    case MatterTypes.CLEAR_PAGINATED_MATTERS:
        return {
            ...state,
            paginatedMattersStatus: internals.initial().paginatedMattersStatus,
            paginatedMatters: internals.initial().paginatedMatters,
            paginatedMatterTypes: internals.initial().paginatedMatterTypes,
        };
    // Site Management / Matter Types
    case MatterTypes.GET_MATTER_TYPE_COLORS_SUCCESS: {
        return {
            ...state,
            matterColors: payload,
        };
    }
    case MatterTypes.GET_MATTER_TYPES_SUCCESS: {
        const matterTypes = MatterTypeTransform(payload);

        return {
            ...state,
            matterTypes,
        };
    }
    case MatterTypes.GET_MATTER_TYPES_COUNT_SUCCESS: {
        return {
            ...state,
            matterTypesCount: { ...payload },
        };
    }
    case MatterTypes.UPDATE_PAGINATED_MATTER_TYPES_PARAMS: {
        const newState = { ...state };
        newState.paginatedMatterTypes.params = {
            ...state.paginatedMatterTypes.params,
            ...payload,
        };
        return newState;
    }
    case MatterTypes.GET_PAGINATED_MATTER_TYPES_BEGIN:
        return {
            ...state,
            paginatedMatterTypesStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case MatterTypes.GET_PAGINATED_MATTER_TYPES_ERROR:
        return {
            ...state,
            paginatedMatterTypesStatus: {
                error: true,
                errorMsg: '',
                completed: true,
            },
        };
    case MatterTypes.GET_PAGINATED_MATTER_TYPES_SUCCESS:
        return {
            ...state,
            paginatedMatterTypesStatus: {
                error: false,
                errorMsg: '',
                completed: true,
            },
            paginatedMatterTypes: {
                ...state.paginatedMatterTypes,
                results: MatterTypeTransform(payload.results),
                total: payload.total,
            },
        };
    // End Site Management / Matter Types
    case MatterTypes.MARK_MATTER_CLOSED_SUCCESS:
        return {
            ...state,
            markMatterClosedStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.MARK_MATTER_OPEN_SUCCESS:
        return {
            ...state,
            markMatterOpenStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.MARK_MATTER_READY_FOR_REVIEW_BEGIN:
        return {
            ...state,
            markMatterReadyForReviewStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.MARK_MATTER_READY_FOR_REVIEW_SUCCESS:
        return {
            ...state,
            markMatterReadyForReviewStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.MARK_MATTER_READY_FOR_REVIEW_ERROR:
        return {
            ...state,
            markMatterReadyForReviewStatus: {
                error: true,
                completed: true,
            },
        };
    case MatterTypes.ASSIGN_ATTORNEYS_BEGIN:
        return {
            ...state,
            assignAttorneysStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.ASSIGN_ATTORNEYS_SUCCESS:
        return {
            ...state,
            assignAttorneysStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.ASSIGN_ATTORNEYS_ERROR:
        return {
            ...state,
            assignAttorneysStatus: {
                error: true,
                completed: true,
            },
        };
    case MatterTypes.DOCUMENTS_UPLOADED_BEGIN:
        return {
            ...state,
            documentsUploadedStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.DOCUMENTS_UPLOADED_SUCCESS:
        return {
            ...state,
            documentsUploadedStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.DELETE_MATTER_BEGIN:
        return {
            ...state,
            deleteMatterStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.DELETE_MATTER_SUCCESS:
        return {
            ...state,
            deleteMatterStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.DELETE_MATTER_ERROR:
        return {
            ...state,
            deleteMatterStatus: {
                error: true,
                completed: true,
            },
        };
    case MatterTypes.PUBLISH_MATTER_BEGIN:
        return {
            ...state,
            createMatterStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.PUBLISH_MATTER_SUCCESS:
        return {
            ...state,
            createMatterStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.CREATE_MATTER_UPDATE:
        return {
            ...state,
            createMatter: {
                ...state.createMatter,
                ...payload,
            },
        };
    case MatterTypes.RESET_MATTER_UPDATE:
        return {
            ...state,
            createMatter: createMatterInitialState,
            createMatterStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };

    case MatterTypes.GET_COLUMNS_SUCCESS: {
        return {
            ...state,
            importMatters: {
                ...state.importMatters,
                ...payload, // header: [], firstRow: [], id: #
            },
        };
    }
    case MatterTypes.IMPORT_LEGAL_SERVER_BEGIN:
        return {
            ...state,
            importLegalServerDataStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.IMPORT_LEGAL_SERVER_SUCCESS:
        return {
            ...state,
            importLegalServerDataStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.IMPORT_LEGAL_SERVER_UPDATE:
        return {
            ...state,
            importLegalServerData: {
                ...state.importLegalServerData,
                ...payload,
            },
        };
    case MatterTypes.RESET_IMPORT_LEGAL_SERVER_UPDATE:
        return {
            ...state,
            importLegalServerData: importLegalServerInitialState,
            importLegalServerDataStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case MatterTypes.IMPORT_MATTERS_BEGIN:
        return {
            ...state,
            importMattersStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.IMPORT_MATTERS_SUCCESS:
        return {
            ...state,
            importMattersStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.IMPORT_MATTERS_UPDATE:
        return {
            ...state,
            importMatters: {
                ...state.importMatters,
                ...payload,
            },
        };
    case MatterTypes.RESET_IMPORT_MATTERS_UPDATE:
        return {
            ...state,
            importMatters: importMattersInitialState,
            importMattersStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case MatterTypes.GET_MATTER_FULL_BEGIN:
        return {
            ...state,
            viewMatterStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };
    case MatterTypes.GET_MATTER_FULL_SUCCESS:
        return {
            ...state,
            viewMatter: payload,
            viewMatterStatus: {
                error: false,
                errorMsg: '',
                completed: true,
            },
        };
    case MatterTypes.GET_MATTER_FULL_ERROR:
        return {
            ...state,
            viewMatter: payload,
            viewMatterStatus: {
                error: true,
                errorMsg: '',
                completed: true,
            },
        };
    case MatterTypes.GET_RECCOMENDED_ATTORNEYS_BEGIN:
        return {
            ...state,
            recommendedAttoreys: [],
        };
    case MatterTypes.GET_RECCOMENDED_ATTORNEYS_SUCCESS:
        return {
            ...state,
            recommendedAttoreys: payload, // TODO, will this data be available if the user closes a matter and reopemns the same one?
        };
    case MatterTypes.REPORT_ISSUE_BEGIN:
        return {
            ...state,
            reportIssueStatus: {
                error: false,
                completed: false,
            },
        };
    case MatterTypes.REPORT_ISSUE_SUCCESS:
        return {
            ...state,
            reportIssueStatus: {
                error: false,
                completed: true,
            },
        };
    case MatterTypes.REPORT_ISSUE_ERROR:
        return {
            ...state,
            reportIssueStatus: {
                error: true,
                completed: true,
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default MattersReducer;
