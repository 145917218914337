import WebClient from '../utils/web-client';
import UsersTypes from '../action-types/users';
import removeEmpty from '../utils/remove-empty-keys';
import { APIError, APISuccess } from './app';
import { GetMatterFull } from './matters';
import { getPaginatedUsersParams } from '../selectors/users';

const ResendUserInviteBegin = () => ({
    type: UsersTypes.RESEND_USER_INVITE_BEGIN,
});
const ResendUserInviteSuccess = () => ({
    type: UsersTypes.RESEND_USER_INVITE_SUCCESS,
});
const ResendUserInviteError = () => ({
    type: UsersTypes.RESEND_USER_INVITE_ERROR,
});

export const ResendUserInvite = (id, onSuccess, onError) => async (dispatch) => {
    dispatch(ResendUserInviteBegin());

    try {
        await WebClient.post('/users/invite', { id });
        dispatch(ResendUserInviteSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError());
        dispatch(ResendUserInviteError());

        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        if (onError) onError(errorType);
    }
};

const GetAllAttorneyUsersBegin = () => ({
    type: UsersTypes.GET_ALL_ATTORNEY_USERS_BEGIN,
});
const GetAllAttorneyUsersSuccess = (payload) => ({
    type: UsersTypes.GET_ALL_ATTORNEY_USERS_SUCCESS,
    payload,
});
const GetAllAttorneyUsersError = () => ({
    type: UsersTypes.GET_ALL_ATTORNEY_USERS_ERROR,
});

export const GetAllUsers = (filter) => async (dispatch) => {
    dispatch(GetAllAttorneyUsersBegin());

    try {
        const { data } = await WebClient.get(`/users/attorneys/${filter || 'all'}`);
        dispatch(GetAllAttorneyUsersSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetAllAttorneyUsersError());
    }
};

export const UpdatePaginatedUsersParams = (payload) => ({
    type: UsersTypes.UPDATE_PAGINATED_USERS_PARAMS,
    payload,
});

const GetPaginatedUsersBegin = () => ({
    type: UsersTypes.GET_PAGINATED_USERS_BEGIN,
});
const GetPaginatedUsersSuccess = (payload) => ({
    type: UsersTypes.GET_PAGINATED_USERS_SUCCESS,
    payload,
});
const GetPaginatedUsersError = () => ({
    type: UsersTypes.GET_PAGINATED_USERS_ERROR,
});

export const GetPaginatedUsers = () => async (dispatch, getState) => {
    dispatch(GetPaginatedUsersBegin());

    const paginatedUsersParams = getPaginatedUsersParams(getState());
    const payload = { ...paginatedUsersParams };
    delete payload.page;
    delete payload.pageSize;
    delete payload.results;
    delete payload.total;

    try {
        const { data } = await WebClient.post(`/users/${paginatedUsersParams.page}/${paginatedUsersParams.pageSize}`, payload);
        dispatch(GetPaginatedUsersSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetPaginatedUsersError());
    }
};

const AddUserBegin = () => ({
    type: UsersTypes.ADD_USER_BEGIN,
});
const AddUserSuccess = () => ({
    type: UsersTypes.ADD_USER_SUCCESS,
});
const AddUserError = () => ({
    type: UsersTypes.ADD_USER_ERROR,
});

export const AddUser = (data, onSuccess) => async (dispatch) => {
    dispatch(AddUserBegin());

    const payload = { ...data };
    const groomedPayload = removeEmpty(payload);

    try {
        await WebClient.post('/users', groomedPayload);
        dispatch(AddUserSuccess());
        dispatch(APISuccess('User successfully added.'));
        dispatch(GetPaginatedUsers()); // assumes we are on the site management / users page
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorMsg = 'Error adding user.';
        if (error.response && (error.response.status === 409)) {
            errorMsg = 'Duplicate Email: This account already exists, or once existed and has since been deleted.';
        }
        dispatch(APIError(errorMsg));
        dispatch(AddUserError());
    }
};

const EditUserDetailsBegin = () => ({
    type: UsersTypes.EDIT_USER_DETAILS_BEGIN,
});
const EditUserDetailsSuccess = () => ({
    type: UsersTypes.EDIT_USER_DETAILS_SUCCESS,
});
const EditUserDetailsError = () => ({
    type: UsersTypes.EDIT_USER_DETAILS_ERROR,
});

export const EditUserDetails = (userId, data, onSuccess, onError, matterId) => async (dispatch) => {
    dispatch(EditUserDetailsBegin());

    try {
        await WebClient.patch(`/users/${userId}`, data);
        dispatch(EditUserDetailsSuccess());
        if (matterId) dispatch(GetMatterFull(matterId)); // used to update a matter's presentation when editing a user inline
        else if (!matterId) dispatch(GetPaginatedUsers()); // assumes we are on the site management / users page
        if (onSuccess) onSuccess();
        dispatch(APISuccess('User successfully edited.'));
    } catch (error) {
        let errorMsg = 'Error adding user.';
        if (error.response && (error.response.status === 409)) {
            errorMsg = 'Duplicate Email: This account already exists, or once existed and has since been deleted.';
        }
        if (onError) onError(errorMsg);
        dispatch(EditUserDetailsError());
    }
};

const DeleteUserBegin = () => ({
    type: UsersTypes.DELETE_USER_BEGIN,
});
const DeleteUserSuccess = () => ({
    type: UsersTypes.DELETE_USER_SUCCESS,
});
const DeleteUserError = () => ({
    type: UsersTypes.DELETE_USER_ERROR,
});

export const DeleteUser = (userId, onSuccess, matterId) => async (dispatch) => {
    dispatch(DeleteUserBegin());

    try {
        await WebClient.delete(`/users/${userId}`);
        dispatch(DeleteUserSuccess());
        if (matterId) dispatch(GetMatterFull(matterId)); // used to update a matter's presentation when editing a user inline
        else if (!matterId) dispatch(GetPaginatedUsers()); // assumes we are on the site management / users page
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(APIError());
        dispatch(DeleteUserError());
    }
};
