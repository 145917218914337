import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual, reject } from 'lodash';

const getMatterState = (state) => state.matters;

export const getTimesByUser = createSelector(
    getMatterState,
    (matterState) => matterState.viewMatter.timesByUser,
);

export const getMatterMessageOptOutStatus = createSelector(
    getMatterState,
    (matterState) => matterState.viewMatter.applicant.smsOptOut,
);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(
    defaultMemoize,
    isEqual,
);

export const getMatterMessages = createDeepEqualSelector(
    getMatterState,
    (matterState) => matterState.viewMatter.messages,
);

export const getImportLegalServerData = createSelector(
    getMatterState,
    (matterState) => matterState.importLegalServerData,
);

export const getImportMattersFormData = createSelector(
    getMatterState,
    (matterState) => matterState.importMatters,
);

export const getMattersReportCount = createSelector(
    getMatterState,
    (matterState) => matterState.mattersReportCount,
);

export const getUploadedFileList = createSelector(
    getMatterState,
    (matterState) => matterState.uploadedFileList,
);

export const getMattersJSON = createSelector(
    getMatterState,
    (matterState) => matterState.mattersJSON,
);

export const getActivityLog = createSelector(
    getMatterState,
    (matterState) => matterState.activityLog,
);

export const getMattersCountByType = createSelector(
    getMatterState,
    (matterState) => matterState?.mattersCount?.matterTypes,
);
export const getMattersCountByStatus = createSelector(
    getMatterState,
    (matterState) => matterState?.mattersCount?.statuses,
);

export const getCreateMatterFormData = createSelector(
    getMatterState,
    (matterState) => matterState.createMatter,
);
export const getViewMatter = createSelector(
    getMatterState,
    (matterState) => matterState.viewMatter,
);

export const getViewMatterStatus = createSelector(
    getMatterState,
    (matterState) => matterState.viewMatterStatus,
);

export const getViewMatterId = createSelector(
    getMatterState,
    (matterState) => (matterState.viewMatter ? matterState.viewMatter.id : null),
);

export const getFilledForms = createSelector(
    getMatterState,
    (formState) => formState.viewMatter.filledForms,
);

export const getApplicantEmail = createSelector(
    getMatterState,
    (formState) => (formState.viewMatter.applicant ? formState.viewMatter.applicant.email : null),
);

export const getAssignedAttoreys = createSelector(
    getMatterState,
    (matterState) => matterState.viewMatter.assignees || [],
);

export const getRecommendedAttoreys = createSelector(
    getMatterState,
    (matterState) => matterState.recommendedAttoreys || [],
);

export const getPaginatedMatters = createSelector(
    getMatterState,
    (matterState) => matterState.paginatedMatters.results,
);

// used to present 'getPaginatedMatterParams' with a cleaned state to compare
const cleanPaginatedMattersParams = createSelector(
    getMatterState,
    (matterState) => {
        const cleanedParams = { ...matterState.paginatedMatters.params };
        // this is structured to allow for filtering or grooming of params, not currently being used.
        return cleanedParams;
    },
);

// uses lodash.isequal to compare state of the object
export const getPaginatedMattersParams = createDeepEqualSelector(
    cleanPaginatedMattersParams,
    (cleanedMattersState) => cleanedMattersState,
);

export const getTotalMattersCount = createSelector(
    getMatterState,
    (matterState) => matterState.paginatedMatters.total,
);

// Site Management / Matter Types
export const getUnusedMatterColors = createSelector(
    getMatterState,
    // remove any color that is used in other matter types
    (matterState) => reject(matterState.matterColors, { taken: true }),
);
export const getMatterColors = createSelector(
    getMatterState,
    (matterState) => matterState.matterColors,
);
export const getMatterTypes = createSelector(
    getMatterState,
    (matterState) => matterState.matterTypes,
);

export const getPaginatedMatterTypes = createSelector(
    getMatterState,
    (matterState) => matterState.paginatedMatterTypes.results,
);

const cleanPaginatedMatterTypesParams = createSelector(
    getMatterState,
    (matterState) => {
        const cleanedParams = { ...matterState.paginatedMatterTypes.params };
        return cleanedParams;
    },
);

export const getPaginatedMatterTypesParams = createDeepEqualSelector(
    cleanPaginatedMatterTypesParams,
    (cleanedMatterTypeState) => cleanedMatterTypeState,
);

export const getTotalMatterTypesCount = createSelector(
    getMatterState,
    (matterState) => matterState.paginatedMatterTypes.total,
);
// END Site Management / Matter Types
